.ant-modal-wrap {
    background-color: #1C46F3;
    background-image:
            radial-gradient(circle at 50% 0%, rgba(255,255,255,0.5) 0%, rgba(255,255,255,0) 70%),
            radial-gradient(circle at 50% 25%, rgba(255,255,255,0.5) 0%, rgba(255,255,255,0) 70%),
            radial-gradient(circle at 50% 50%, rgba(255,255,255,0.5) 0%, rgba(255,255,255,0) 70%),
            radial-gradient(circle at 50% 50%, rgba(255,255,255,0.5) 0%, rgba(255,255,255,0) 70%),
            radial-gradient(circle at 50% 50%, rgba(255,255,255,0.5) 0%, rgba(255,255,255,0) 70%);
    background-size: 100% 2px;
    background-repeat: no-repeat;
    background-position:
            0% 10%,
            0% 30%,
            0% 50%,
            0% 70%,
            0% 90%;
}
