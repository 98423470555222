.css-dev-only-do-not-override-gzal6t.ant-upload.ant-upload-drag:hover{
    border-color: #1C46F3;
}

.ant-upload-text{
    font-size: 16px !important;
}

.ant-upload-hint {
    font-size: 14px !important;
}

.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-light.bg-transparent.pl-0.text-textSiderDull.text-sm.font-outfit.css-gzal6t{
    border-inline-end: none !important;
}