

.visibility-btn {
    font-size: 0.625rem;
    border-radius: 3px;
    padding-left: 6px;
    padding-right: 7px;
    padding-top: 3px;
    padding-bottom: 4px;
}

.header-plugins {
    max-width: 18.3rem;
    width: 100%;
    padding: 2px;
    border-radius: 3.375rem;
}

.ant-switch.ant-switch-small.css-dev-only-do-not-override-gzal6t{
   background-color: black !important;
}

.ant-switch.ant-switch-small.css-dev-only-do-not-override-gzal6t.ant-switch-checked{
    background: #1677ff !important;
}

.ant-dropdown-menu-title-content button{
    background-color: black;
}

