.recent-items-card,
.recent-items-card-expanded {
  width: 30% !important;
  margin-right: 2rem !important;
  align-items: flex-start !important;
  padding-top: 0.3rem;
  padding-bottom: 0.9rem;
}

.recent-items-card {
  height: 12rem !important;

  overflow: hidden;
}

.recent-items-card-expanded {
  height: fit-content !important;
}

.recent-items-card:hover,
.recent-items-card-expanded:hover {
  background-color: white !important;
}

.recent-card-content {
  height: 11rem;
}
.recent-card-text-content {
  height: 7.25rem;
  overflow: hidden;
}
.recent-card-content-expanded {
  height: fit-content;
}
.recent-card-text-content-expanded {
  height: fit-content;
  overflow: visible;
}
