/* Add these styles for the flip animation */

.flip-container {
    perspective: 1000px;
}

.flip-card {
    width: 100%;
    height: 100%;
    transform-style: preserve-3d;
    transition: transform 0.5s;
}

.flip-card.flipped {
    transform: rotateY(180deg);
}

.blur-background {
    border-radius: 10px;
    background: rgba(29, 29, 29, 0.62);
    backdrop-filter: blur(17.5px);
    width: 97%;
    height: 96%;
  }
  
