@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  font-size: 16px; /* Set your desired root font size here */
}

body {
  font-family: "Poppins", sans-serif;
  overflow: hidden;
  margin: 0px;
  padding: 0px;
}

.chat-layout{
  /* background-image: url("/src/assets/images/background.svg"); */
  /* background-image: url("/src/assets/images/background-dots.svg"); */
  /* background-position-x: center; */
  /* background-size: cover; */
}

.report-layout {
  background-image: url("/src/assets/images/blob1.svg");
  background-repeat: no-repeat;
  background-position: calc(100% + 500px) -190px; /* Adjusts horizontal and vertical position */
  background-size: contain;
  position: relative;
}

.settings-layout{
  background-image: url("/src/assets/images/background-dots.svg");
  background-position-x: center;
  flex-direction: column !important;
}

.chat-landing-page{
  background-image: url("/src/assets/images/blob1.svg");
  background-repeat: no-repeat;
  background-position: calc(100% + 500px) -190px; /* Adjusts horizontal and vertical position */
  background-size: contain;
  position: relative;
}

.main-uploader{
  height: 20rem;
}

.main-uploader > :nth-child(1){
  border: 4px dashed #CDCDCD !important;
}

.ant-table-content{
  font-family: 'Outfit';
  word-spacing: 0.1rem;
}

a:hover{
  color: inherit;
  opacity: 100%;
}

.ant-modal-footer > button:nth-child(1) {
  color: gray;
  font-family: outfit;
  letter-spacing: 0.03rem;
}

.ant-modal-footer > button:nth-child(1):hover {
  color: white !important;
  background-color: black !important;
  border-color: black !important;
  opacity: 100% !important;
}

.ant-modal-footer > button:nth-child(2) {
  color: #3F96FE;
  border-color: #3F96FE;
  opacity: 80%;
  font-family: outfit;
  letter-spacing: 0.03rem;
}

.ant-modal-footer > button:nth-child(2):hover {
  color: #3F96FE;
  border-color: #3F96FE;
  opacity: 100%;
}
