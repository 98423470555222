.input-field:focus {
  outline: none;
}

.custom-textarea::placeholder {
  padding-top: 10px;
}

.custom-textarea:focus::placeholder {
  color: transparent;
}



