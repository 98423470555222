.chat-sider-wrapper > div {
    display: flex !important;
    flex-direction: column !important;
    background-color: #F9F9F9;

  }

  .chat-sider-menu-item{
    margin-block: 0 !important;
    margin-inline: 0 !important;
    height: 22px;
  }


  .ant-dropdown-menu-item {
    font-family: Poppins;
    font-size: 0.8rem !important;
  }

  .workspace-selection button:hover{
    border: 1px solid #000 !important;
    color: #000 !important;
  }

  .recent-workspaces ul>li{
    padding: 0 !important;
    height: 3rem !important;
    padding-left: 0.5rem !important;
    width: 95% !important;
  
  }
  .recent-workspaces >:where(.css-dev-only-do-not-override-gzal6t).ant-menu-light .ant-menu-item-selected, :where(.css-dev-only-do-not-override-gzal6t).ant-menu-light>.ant-menu .ant-menu-item-selected{
    color: white !important;
    background-color: #1f46e2 !important;
  
  }

  .recent-workspaces ul>li>span{
    display: flex !important;
    align-items: center;
    gap: 0.2rem;
  }